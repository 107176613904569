import ImageLinks from './ImageLinks';
import { ProductImages } from './model/Products';
import data from './config/products.json';

const products: ProductImages = data as unknown as ProductImages;

const Home = () => {
    return (
        <ImageLinks list={products.list.reverse()} />
    );
}

export default Home;
