import React from "react";
import { ProductImage } from "./model/Products";

interface NameAndLink {
    name: string;
    link: string;
}

const ProductLink: React.FC<NameAndLink> = (nameAndLink: NameAndLink) => {
    return (
        <li>
            <p>{nameAndLink.name}</p>
            <a href={nameAndLink.link}>{nameAndLink.link}</a>
        </li>
    )
};

const ProductPage: React.FC<ProductImage> = (productImage: ProductImage) => {
    return (
        <div>
            <ul>
                <ProductLink name="Lazada" link={productImage.links.lazada} />
                <ProductLink name="Shopee" link={productImage.links.shopee} />
                <ProductLink name="TikTok Shop" link={productImage.links.tiktok} />
                <hr />
                <li>
                    <img className="product" src={productImage.image} alt={productImage.route} />
                </li>
            </ul>
        </div>
    )
};

export default ProductPage;