import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home';
import products from './config/products.json';
import ProductPage from './ProductPage';

const App = () => {
  return (
    <div className="App">
      <Router>
        <h1>Jomigu Social Links</h1>
        <Routes>
          <Route path='/' element={<Home />} />
          {products.list.map((product) => (
            <Route path={`/product/${product.route}`} element={<ProductPage key={product.route} image={product.image} links={product.links} route={product.route} />} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
