import React from 'react';
import { ProductImage, ProductImages } from './model/Products';
import { Link } from 'react-router-dom';

const ImageLinks: React.FC<ProductImages> = (productImages: ProductImages) => {
    return (
        <ul>
            {productImages.list.map((productImage: ProductImage) => (
                <li>
                    <Link to={`/product/${productImage.route}`}>
                        <img src={productImage.image} alt={productImage.route} />
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default ImageLinks;
